.container {
  width: 79.2rem;
  height: 100%;
  background-color: #fff;
  border-radius: 0.8rem;
  overflow: hidden;
}

.imageCountContainer {
  display: flex;
}

.icon {
  margin-right: 1.3rem;
  opacity: 0.6;
}

.imageCount {
  color: #9c9797;
}

.imagesWrapper {
  height: 100%;
  overflow-y: hidden;
  overflow: auto;
}

.imagesWrapper::-webkit-scrollbar {
  display: none;
}

.images {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  padding: 1.65rem 1.2rem;
  padding-bottom: 20rem;
}

.images::-webkit-scrollbar {
  display: none;
}

.image {
  width: 18rem;
  height: 18rem;
  margin: 0 0.6rem;
  margin-bottom: 1.2rem;
}

.slideContainer {
  position: relative;
  width: 82rem;
  height: 58rem;
  margin: auto;
  transform: translateY(2rem);
}

.slideContainer div[data-type="prev"] {
  background-color: #ffb707;
  transform: translateX(2rem);
}

.slideContainer div[data-type="prev"]:hover {
  background-color: #ffb707;
}

.slideContainer div[data-type="prev"] span {
  border: 2px solid #010202;
}

.slideContainer div[data-type="next"] {
  background-color: #ffb707;
  transform: translateX(-2rem);
}

.slideContainer div[data-type="next"]:hover {
  background-color: #ffb707;
}

.slideContainer div[data-type="next"] span {
  border: 2px solid #010202;
}

.eachFade {
  position: relative;
  display: flex;
  flex-flow: column;
  width: 100%;
  height: 58rem;
}

.eachFade .imageContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 4rem);
}

.eachFade .imageContainer img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.indexCountContainer {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  width: 100%;
  height: 4rem;
}

.indexCount {
  color: #f2f2f2;
  font-size: 16px;
  letter-spacing: 0.53px;
  line-height: 24px;
}

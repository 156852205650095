.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.2rem;
}

.expanded.container {
  margin-bottom: 1.6rem;
}

.product {
  position: relative;
}

.checkbox {
  position: absolute;
  left: -3rem;
  top: 0;
}

.productNameContainer {
  display: flex;
  align-items: center;
  position: relative;
}

.bold {
  font-weight: 700;
}

.arrowIcon {
  cursor: pointer;
  position: absolute;
  left: 0;
}

.productName {
  color: #6e6e6e;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 16px;
  cursor: pointer;
  margin-left: 1.7rem;
}

.upcText {
  color: #a6a2a2;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  cursor: pointer;
}

.addUpc {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #ffa500;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  cursor: pointer;
}

.imageSetsContainer {
  margin-bottom: 1.7rem;
  margin-left: 3.7rem;
}

.imageSetContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.8rem;
}

.imageSetContainer:last-child {
  margin-bottom: 1.4rem;
}

.imageSetName {
  color: #9d9d9d;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 18px;
  cursor: pointer;
}

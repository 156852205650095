.backgroundNotLoggedIn {
  display: flex;
  align-items: center;
  background-color: #333;
  height: 10rem;
}

.containerNotLoggedIn {
  display: flex;
  justify-content: space-between;
}

.authContainer {
  display: flex;
  align-items: center;
}

.authBtn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #ffb707;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
}

.spacer {
  width: 2.6rem;
}

.backgroundLoggedIn {
  position: fixed;
  background-color: #333;
  width: 12rem;
  height: 100%;
}

.containerLoggedIn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding-top: 1rem;
  padding-bottom: 2.4rem;
}

.logo {
  margin-bottom: 2.8rem;
}

.itemsContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

.userContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
}

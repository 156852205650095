.container {
  display: flex;
  margin-bottom: 2.4rem;
}

.star {
  cursor: pointer;
  margin-right: 0.7rem;
}

.readOnly.star {
  cursor: inherit;
}

.star:last-child {
  margin-right: 0;
}

.btns {
  display: flex;
  align-items: center;
}

.btns button:first-child {
  margin-right: 2rem;
}

.cancelBtn {
  margin-right: 2.85rem;
}

.connected {
    margin-top: 3rem;
    font-size: 1.5rem;
}

.paymentContainer {
    padding: 4rem;
    border: 1px solid #e3e1e1;
    background: #fff;
}

.connectButton {
    margin-top: 3rem;
    cursor: pointer;
}

.connectImage {
    height: 4rem;
}

.connectImage:active {
    height: 4rem;
}

.title {
    font-size: 2rem;
    font-weight: bold;
}

.subscriptionPlans {
    appearance: auto;
}

.formBox {
    display: flex;
    align-items: center;
    margin-top: 13px;
    cursor: pointer;
    width: 20rem;
}

.formBox label {
    font-size: 1.5rem;
    margin-left: 1rem;
    cursor: pointer;
}

.formBox input {
    cursor: pointer;
}

.subscriptionForm {
    margin-top: 2.5rem;
}

.payNow {
    background: #556cd6;
    color: #fff;
    border: none;
    width: 18rem;
    font-size: 2rem;
    margin-top: 2rem;
    padding: 1rem 1rem;
}

.payNow:active {
    background: #5056ab;
    color: #fff;
}
.btn {
  margin-left: 2.45rem;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
  display: flex;
}

.icon:first-child {
  margin-left: 0;
}

.container {
  display: inline-flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  position: relative;
}

.goBack {
  position: absolute;
  top: -3.2rem;
}

.columns {
  display: flex;
  height: 100%;
  flex-direction: row-reverse;
}

.left {
  margin-right: 1.6rem;
}

.right {
  overflow: hidden;
}

.title {
  color: #333;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.53px;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
}

.text {
  color: #9c9797;
  margin-bottom: 1rem;
}

.finalRatingsContainer {
  display: flex;
  margin-bottom: 2.4rem;
}

.myRating {
  margin-right: 4.2rem;
}

.submitedComment {
  color: #969696;
  font-size: 1.1rem;
  letter-spacing: 0.37px;
  line-height: 1.6rem;
}

.container {
  margin-top: 16rem;
}

.form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.title {
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 23px;
  text-align: center;
  margin-bottom: 1rem;
}

.inputsContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  width: 32rem;
}

.inputWrapper {
  width: 100%;
  position: relative;
  margin-top: 2rem;
}

.forgotPassLink {
  align-self: flex-end;
  color: #ffb707;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 17px;
  margin-top: 1rem;
  margin-bottom: 2rem;
}

@media all and (max-width: 540px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10rem);
    margin-top: 0;
  }

  .form {
    width: 100%;
  }

  .inputsContainer {
    width: 100%;
  }
}

.container {
  display: flex;
  justify-content: space-between;
}

.box {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 37.2rem;
  height: 6.5rem;
  border: 1px dashed #ffa500;
}

.content {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.content p {
  color: #ffa500;
  font-weight: bold;
}

.icon {
  margin-right: 1.6rem;
}

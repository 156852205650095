.container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.small {
  width: 5rem;
  height: 5rem;
}

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
}

.overlay {
  position: absolute;
  background-color: #000;
  opacity: 0.6;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.closeIcon {
  position: absolute;
  top: 4rem;
  right: 4rem;
  width: 2.4rem;
  height: 2.4rem;
  cursor: pointer;
}

.closeIcon img {
  width: 100%;
  height: auto;
}

.children {
  position: relative;
}

.container {
  display: flex;
  flex-flow: column;
}

.search {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.searchWrapper {
  width: 33.9rem;
}

.icons {
  display: flex;
}

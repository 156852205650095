.container {
  margin-top: 16rem;
}

.form {
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}

.title {
  color: #333;
  font-weight: 700;
  letter-spacing: 1.27px;
  line-height: 4.7rem;
  text-align: center;
  margin-bottom: 3rem;
}

.inputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 32rem;
  margin-bottom: 2rem;
}

.successContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  overflow: hidden;
  width: 40rem;
}

.successMsg {
  margin-bottom: 2rem;
}

.goToLogin {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  border-radius: 5px;
  background-color: #555;
  color: white;
  border: none;
  width: 8rem;
  height: 2rem;
}

.goToLogin a {
  color: white;
}

@media all and (max-width: 540px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10rem);
    margin-top: 0;
  }

  .form {
    width: 100%;
  }

  .inputContainer {
    width: 100%;
  }

  .submitBtn {
    width: 100%;
  }

  .loadingBtn {
    width: 100%;
  }
}

.validatedListRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  width: 100%;
  padding: 0 3rem;
  height: 5.6rem;

  font-size: 1.4rem;
  color: #6e6e6e;
  background-color: #ffffff;
  border-radius: 0.8rem;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(195, 195, 195, 0.5);
  margin-bottom: 0.8rem;
  cursor: pointer;
}

.category {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 4rem;
  white-space: nowrap;
}

.contentType {
  display: flex;
  align-items: center;
}

.icon {
  margin-right: 1.4rem;
}

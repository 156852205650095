.container {
  margin-bottom: 0.8rem;
  display: flex;
  max-width: 32rem;
  align-items: center;
}

.checkboxInput {
  max-height: 1.6rem;
  max-width: 1.6rem;
  min-height: 1.6rem;
  min-width: 1.6rem;
  margin: 0;
  cursor: pointer;
}

.clickDisabled.checkboxInput {
  cursor: inherit;
}

.checked {
  background-image: url("../../static/svg/check-solid.svg");
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: #ffb707;
  border-radius: 1px;
  max-height: 1.6rem;
  max-width: 1.6rem;
  min-height: 1.6rem;
  min-width: 1.6rem;
  margin-right: 0.8rem;
}

.unchecked {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 1.6rem;
  max-width: 1.6rem;
  min-height: 1.6rem;
  min-width: 1.6rem;
  border: 1px solid #e3e1e1;
  margin-right: 0.8rem;
}

.checkIcon {
  width: 1rem;
  position: absolute;
}

.checkboxLabel {
  color: #6e6e6e;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 18px;
  cursor: pointer;
}

.clickDisabled.checkboxLabel {
  cursor: inherit;
}

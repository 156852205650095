.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 3.2rem;
  margin: 0 5.6rem;
}

.title {
  margin-bottom: 2.4rem;
}

.button {
  margin-bottom: 1.3rem;
}

.children {
  margin: 0 5.6rem;
}

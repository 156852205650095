.container {
  position: relative;
  padding: 5.2rem 5.6rem;
  background-color: #f2f2f2;
  height: 100vh;
  overflow: hidden;
}

.save {
  position: fixed;
  width: calc(100% - 53.6rem);
  bottom: 0;
  right: 0;
}

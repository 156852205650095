.notification {
  display: flex;
  flex-grow: 0;
  justify-content: space-between;
  align-items: center;
  opacity: 0.9;
  padding: 1.2rem 1.6rem;
  border-radius: 2rem;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.5rem;
  margin-bottom: 0.8rem;
}

.icon {
  margin-right: 1rem;
}

.message {
  display: flex;
}

.dismiss {
  width: 1.6rem;
  height: 1.6rem;
  margin-left: 1.6rem;
}

.success {
  background-color: #c8ebdf;
}

.warning {
  background-color: #fcb6b6;
}

.title {
  color: #333;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.53px;
  line-height: 2.4rem;
  margin-bottom: 1.6rem;
}

.text {
  color: #9c9797;
  margin-bottom: 1rem;
}

.textareaWrapper {
  position: relative;
  margin-bottom: 2.4rem;
}

.textarea {
  width: 100%;
  min-height: 8rem;
  border: 1px solid #e3e1e1;
  border-radius: 2px;
  padding: 0.9rem 1.5rem;
  resize: none;
}

.commentContainer {
  min-height: 8rem;
  margin-bottom: 2.4rem;
}

.comment {
  color: #969696;
  font-size: 1.1rem;
  letter-spacing: 0.37px;
  line-height: 1.6rem;
}

.buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.skip {
  display: flex;
  align-items: center;
  color: #ffa500;
  font-size: 1.2rem;
  font-weight: bold;
  line-height: 1.6rem;
  cursor: pointer;
}

.skip:disabled {
  cursor: inherit;
}

.icon {
  margin-left: 0.4rem;
}

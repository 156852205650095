.background {
  background-color: #f2f2f2;
  height: 100vh;
  padding: 2.4rem;
}

.container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #fff;
  height: 100%;
}

.title {
  color: #333;
  font-size: 38px;
  font-weight: bold;
  letter-spacing: 1.27px;
  line-height: 47px;
  margin-bottom: 1.6rem;
}

.title {
  margin-bottom: 2.4rem;
}

.inputWrapper {
  position: relative;
  width: 32rem;
  margin-bottom: 2.4rem;
}

.inputWrapper:last-child {
  margin-bottom: 0;
}

.forgotPasswordContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.6rem;
}

.forgotPassLink {
  color: #ffb707;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 17px;
}

.dots {
  width: 321px;
  border-bottom: 1px dotted #cccccc;

  margin: 3.1rem 0;
}

.newPaswordContainer {
  margin-bottom: 3.5rem;
}

.submitContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.saveBtn {
  padding: 1.1rem 3.6rem;
  background-color: #ffb707;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.47px;
  line-height: 18px;
  text-align: center;
  border: none;
  border-radius: 2rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
}

.loading {
  padding: 1.1rem 3.6rem;
  color: #000000;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.47px;
  line-height: 18px;
  text-align: center;
  border: none;
  border-radius: 2rem;
  margin-bottom: 1.6rem;
  cursor: pointer;
  background-color: #b3b3b3;
}
.cancelBtn {
  border: none;
  background-color: transparent;
  color: #898989;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.productContainer {
  margin-bottom: 1.6rem;
}

.inputsContainer {
  margin-bottom: 1.55rem;
}

.inputWrap {
  margin-bottom: 1rem;
}

.label {
  color: #6e6e6e;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 18px;
  margin-bottom: 1.15rem;
}

.saveBtn {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #ffb707;
  padding: 0.85rem 1.6rem;
  margin-right: 1.6rem;
  color: #000;
  font-family: "Source Sans Pro";
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
}

.loadingBtn {
  cursor: pointer;
  background-color: transparent;
  border: 1px solid #ffdc83;
  padding: 0.85rem 1.6rem;
  color: #898989;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  margin-right: 1.6rem;
}

.cancelBtn {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #898989;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  cursor: pointer;
}

.error {
  color: #bb271d;
  font-size: 1rem;
  padding-left: 1.2rem;
}

.container {
  display: flex;
  align-items: center;
  background-color: transparent;
  border: none;
  padding: 0;
  cursor: pointer;
}

.icon {
  margin-right: 0.8rem;
}

.highlight {
  color: #ffa500;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 0.43px;
  line-height: 17px;
  text-align: center;
}

.regular {
  color: #333;
  font-size: 13px;
  letter-spacing: 0.43px;
  line-height: 17px;
}

.bold {
  color: #6e6e6e;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0.47px;
  line-height: 18px;
}

.regularGrey {
  color: #6e6e6e;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 18px;
}

.clickDisabled {
  cursor: inherit;
}

.productContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.8rem;
  transform: translateX(0.2rem);
  padding-right: 1rem;
}

.faded {
  opacity: 0.3;
}

.imageSetContainer {
  padding-left: 2.25rem;
}

.imageSetFormContainer {
  margin-top: 0.8rem;
  margin-bottom: 1.6rem;
}

.upcContainer {
  position: relative;
}

.addUpc {
  border: none;
  background-color: transparent;
  padding: 0;
  color: #ffa500;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  cursor: pointer;
}

.faded .addUpc {
  cursor: inherit;
}

.clickDisabled.addUpc {
  cursor: inherit;
}

.upcText {
  color: #a6a2a2;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: right;
}

.editIcon {
  visibility: hidden;
  position: absolute;
  top: 0;
  right: -1.7rem;
  cursor: pointer;
}

.faded.productContainer:hover .editIcon {
  visibility: hidden;
}

.clickDisabled.productContainer:hover .editIcon {
  visibility: hidden;
}

.productContainer:hover .editIcon {
  visibility: visible;
}

.addSetBtn {
  margin: 0.8rem 0;
}

.editProductContainer {
  padding-left: 1.8rem;
  margin-bottom: 1.6rem;
}

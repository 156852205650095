.backgroundOverlay {
  z-index: 1;
  position: fixed;
  background-color: #000;
  opacity: 0.6;
  height: 100vh;
  left: 0;
  width: 53.5rem;
  cursor: pointer;
}

.container {
  margin-top: 16rem;
}

.iconContainer {
  display: flex;
  justify-content: center;
  margin-top: 16rem;
  transform: translateY(-2rem);
}

.form {
  margin-top: 16rem;
  max-width: 37.8rem;
}

.icon {
  height: 248px;
  width: 334px;
}

.title {
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 23px;
  margin-bottom: 1rem;
}

.inputsContainer {
  display: flex;
  flex-flow: column;
  position: relative;
  width: 32rem;
}

.inputWrapper {
  width: 100%;
  position: relative;
  margin-top: 2rem;
}

.loginInput {
  background: transparent;
  border: none;
  border: 1px solid #969696;
  width: 100%;
  height: 4.2rem;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: 0.5px;
  padding: 1.2rem;
  margin: 1rem 0;
}

.forgotPassLink {
  align-self: flex-end;
  color: #ffb707;
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 17px;
  margin-top: 1rem;
}

.submitBtn {
  cursor: pointer;
  background-color: #ffb707;
  font-weight: 700;
  text-align: center;
  color: #000;
  border: none;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  width: 9.8rem;
  height: 4rem;
  margin-top: 1rem;
}

.loadingBtn {
  cursor: pointer;
  background-color: #ffdc83;
  font-weight: 700;
  text-align: center;
  color: #000;
  border: none;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  width: 9.8rem;
  height: 4rem;
  margin-top: 1rem;
}

@media all and (max-width: 768px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10rem);
    margin-top: 0;
    max-width: initial;
  }

  .iconContainer {
    display: none;
  }
}

@media all and (max-width: 540px) {
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10rem);
    margin-top: 0;
    max-width: initial;
  }

  .form {
    width: 100%;
  }

  .inputsContainer {
    width: 100%;
  }

  .submitBtn {
    width: 100%;
  }

  .loadingBtn {
    width: 100%;
  }
}

.container {
  position: relative;
  background-color: #fff;
  border-radius: 0.8rem;
  max-width: 79.2rem;
  overflow: hidden;
  height: 100%;
}

.backToAllSetsBtn {
  position: absolute;
  top: -2.9rem;
  left: 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  color: #ffa500;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 16px;
}

.backIcon {
  margin-right: 0.6rem;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding-top: 1.6rem;
  padding-bottom: 1.85rem;
  padding-left: 4rem;
  padding-right: 1.6rem;
  height: 9.85rem;
  border-bottom: 1px solid #f2f2f2;
}

.imageSetDetails {
  display: flex;
  flex-flow: column;
}

.status {
  margin-bottom: 0.8rem;
}

.imageSetName {
  display: flex;
  align-items: center;
}

.imageSetName h2 {
  color: #333333;
  font-size: 2rem;
  font-weight: bold;
  letter-spacing: 0.67px;
  line-height: 32px;
  margin-right: 0.4rem;
}

.editIcon {
  visibility: hidden;
  cursor: pointer;
}

.imageSetName:hover .editIcon {
  visibility: visible;
}

.actionBtns {
  display: flex;
  align-items: center;
}

.actionBtn {
  margin-right: 1.6rem;
}

.readyForMyOwnUse div {
  margin-bottom: 0;
}

.readyForMyOwnUse label {
  font-size: 1.2rem !important;
}

.readyForMyOwnUse span {
  margin-right: 0.8rem;
}

.selectedImagesInfo {
  display: flex;
  margin-right: 1.6rem;
}

.discardSelectedImagesIcon {
  height: 1.7rem;
  width: 1.7rem;
  cursor: pointer;
  border-radius: 50%;
  border: 1px solid #e2e2e2;
  margin-right: 1.6rem;
}

.selectedImagesCountText {
  color: #6e6e6e;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 16px;
}

.selectedImagesCount {
  font-weight: bold;
}

.content {
  padding: 2.4rem 1.8rem;
}

.uploadContainer {
  margin-bottom: 2.35rem;
}

.imagesWrapper {
  overflow-y: hidden;
  overflow: auto;
  height: 100vh;
}

.imagesWrapper::-webkit-scrollbar {
  display: none;
}

.images {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 50rem;
}

.imageThumbail {
  position: relative;
  width: 18rem;
  height: 18rem;
  margin-right: 1.2rem;
  margin-bottom: 1.4rem;
}

.imageThumbail:nth-child(4n) {
  margin-right: 0;
}

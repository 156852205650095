.title {
  font-size: 2rem;
  font-weight: bold;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.9rem;
  letter-spacing: 0.05rem;
}

.mandatory {
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  font-size: 1.4rem;
  color: #6e6e6e;
  margin-top: 0.8rem;
  margin-bottom: 3.2rem;
}

.categoriesContainer {
  padding: 4rem;
  border: 1px solid #e3e1e1;
  background: #fff;
  height: calc(100vh - 26rem);
  overflow: hidden;
}

.categoriesInnerWrapper {
  height: 100%;
  overflow-y: hidden;
  overflow: auto;
}

.categoriesInnerWrapper::-webkit-scrollbar {
  display: none;
}

.categories {
  margin-top: 2.4rem;
}

.categoriesFull {
  display: flex;
}

.categories div {
  margin-bottom: 1.2rem;
}

.categoriesListLeft {
  margin-right: 8.7rem;
}

.save {
  position: fixed;
  width: calc(100% - 12rem);
  right: 0;
  bottom: 0;
}

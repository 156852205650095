.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.container {
  position: absolute;
  width: 64rem;
  height: 39.2rem;
  background-color: #fff;
  padding: 4rem;
  padding-top: 3.2rem;
  top: 8rem;
  left: 4.5rem;
  z-index: 20;
}

.innerContainer {
  position: relative;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: -1.4rem;
  top: -1.4rem;
}

.title {
  color: #000000;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0.53px;
  line-height: 24px;
  margin-bottom: 1.6rem;
}

.list .text {
  color: #000000;
  font-size: 13px;
  letter-spacing: 0.47px;
  line-height: 20px;
}

.text {
  color: #000000;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 20px;
}

.bold {
  font-weight: bold;
}

.form {
  display: flex;
  align-items: center;
}

.input {
  position: relative;

  width: 40rem;
  color: #333;
  font-family: "Source Sans Pro";
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 16px;
  padding: 1.2rem 2rem;
  border: 1px solid #e3e1e1;
  border-radius: 2px;
  background-color: #ffffff;
  margin-right: 2.4rem;
}

.input::placeholder {
  color: #969696;
}

.btn {
  margin-right: 1.6rem;
}

.container {
  min-height: 50rem;

  background-color: #fff;
  border-radius: 0.8rem;
  padding: 2.35rem;
}

.innerContainer {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  border: 1px dashed #e3e1e1;
  border-radius: 8px;
}

.icon {
  margin-top: 14.15rem;
  margin-bottom: 3.2rem;
}

.title {
  margin-bottom: 0.8rem;
}

.subtitle {
  color: #6e6e6e;
  font-size: 16px;
  letter-spacing: 0.53px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 14.5rem;
}

.container {
  display: flex;
}

.leftPanel {
  border-right: 1px solid #e3e1e1;
  min-width: 41.6rem;
  max-width: 41.6rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.productList {
  padding: 3.2rem 2.4rem 4rem 3.2rem;
  overflow-y: hidden;
  overflow: auto;
  padding-bottom: 10rem;
}

.panelHeader {
  margin-bottom: 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.isMultiSelect {
  margin-left: 3.3rem;
}

.rightPanel {
  width: 100%;
  height: 100vh;
  background-color: #fff;
}

.productName {
  padding-top: 3.2rem;
  padding-left: 5.5rem;
  padding-bottom: 2.4rem;
}

.multiSelectContainer {
  position: fixed;
  bottom: 0;
}

.container {
  padding: 4.8rem 5.6rem;
  background: #fff;
  width: 100%;
  border-radius: 0.8rem;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.9rem;
  letter-spacing: 0.05rem;
}

.subtitleWrapper {
  display: flex;
  position: relative;
}

.mandatory {
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  font-size: 12px;
  color: #6e6e6e;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}

.labelContainer {
  margin-bottom: 3.5rem;
  position: relative;
}

.labelContainer:last-child {
  margin-bottom: 0;
}

.inputBox {
  position: relative;
  margin-right: 4rem;
}

.inputContainer {
  margin-bottom: 4rem;
  position: relative;
}

.inputBox input {
  border: 1px solid #e3e1e1;
  width: 32rem;
  font-size: 1.5rem;
  color: #333;
  padding-left: 1.2rem;
  padding-top: 1.7rem;
  padding-bottom: 0.8rem;
  width: 100%;
}

.placeholder {
  position: absolute;
  pointer-events: none;
  left: 1.3rem;
  top: 0.6rem;
  transition: 0.2s ease all;
  color: #969696;
  font-size: 0.9rem;
}

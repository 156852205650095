.sectionSubmit {
  display: flex;
  align-items: center;
  padding: 0 8rem;
  background: #fff;
  height: 8rem;
  justify-content: flex-end;
  border-top: 1px solid #e3e1e1;
}

.default {
  margin-left: 3rem;
  width: 13.5rem;
  line-height: 1.8rem;
  padding: 1.1rem 2rem;
  border-radius: 2rem;
  background-color: #b3b3b3;
  font-size: 1.4rem;
  font-weight: 500;
  color: #6b6a6a;
  border: none;
}

.active.default {
  background-color: #ffb707;
  color: #333;
  cursor: pointer;
}

.loading.default {
  background-color: #ffdc83;
  color: #333;
  cursor: inherit;
}

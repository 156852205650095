.searchContainer {
  width: 100%;
  height: 3.3rem;
  border: 1px solid #e3e1e1;
  display: flex;
  border-radius: 1.6rem;
  padding: 0 10px;
  background-color: #f6f6f6;
}

.searchContainer button {
  margin-left: 0;
  margin-right: 1rem;
  justify-content: center;
  align-items: center;
}

.search {
  width: 100%;
  /* -webkit-appearance: none; */
  background-color: #f6f6f6;
  border: none;
  color: #9c9797;
  font-size: 1.1rem;
  font-family: "Source Sans Pro";
  letter-spacing: 0.37px;
  line-height: 14px;
}

.focus {
  background-color: #fff;
  color: #333333;
}

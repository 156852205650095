.businessInfoFormInnerContainer {
  padding: 4.8rem 5.6rem;
  background: #FFF;
  border: 1px solid #E3E1E1;
  width: 100%;
  margin-top: 3rem;
  margin-left: 4.4rem;
  margin-right: 6.6rem;
}

.sectionGrey {
  background-color: #f2f2f2;
  display: flex;
  align-items: flex-start;
  padding-bottom: 12rem;
}

.sectionSubmit {
  display: flex;
  align-items: flex-end;
  flex-flow: column;
}

.sectionSubmit input {
  margin-right: 8.1rem;
  margin-top: 2rem;
  width: 13.5rem;
  line-height: 1.8rem;
  padding: 1.1rem 2rem;
  border-radius: 2rem;
  background-color: #B3B3B3;
  font-size: 1.4rem;
  font-weight: 500;
  color: #6b6a6a;
}

.active {
  background-color: #FFB707 !important;
  color: #000 !important;
  cursor: pointer;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.9rem;
  letter-spacing: 0.05rem;
}

.mandatory {
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  font-size: 12px;
  color: #6E6E6E;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}

.inputContainer {
  position:relative;
  /* margin-top: 1.6rem; */
}

.subtitleWrapper {
  display: flex;
  position: relative;
}

.essentials {
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: #6E6E6E;
  position: absolute;
  top: 2.5rem;
  left: 3rem;
}
.essentialsCheckbox {
  margin-bottom: 0;
}

.topBox {
  margin-top: 3.2rem;
}

.bottomBox {
  margin-top: 10.2rem;
}

.innerCheckboxContainer {
  margin-left: 3rem;
}

.innerCheckboxContainer div{
  margin-bottom: 1.2rem;
}
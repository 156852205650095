.icon {
  margin-right: 0.9rem;
}

.btn {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.cursorInitial {
  cursor: initial;
}

.round {
  padding: 0.85rem 2.3rem;
  border: 1px solid #ffb707;
  border-radius: 20px;
}

.backgroundYellow {
  background-color: #ffb707;
  border: none;
}

.disabled {
  background-color: #b3b3b3;
  cursor: inherit;
  border: none;
  opacity: 0.5;
}

.loading {
  background-color: #ffdc83;
  cursor: inherit;
  border: none;
}

.text {
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
}

.lightText {
  font-weight: 400;
}

.greyText {
  color: #898989;
}

.yellowText {
  color: #ffa500;
}

.form {
  padding: 4.8rem 5.6rem;
  background: #fff;
  border: 1px solid #e3e1e1;
  width: 100%;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.9rem;
  letter-spacing: 0.05rem;
}

.mandatory {
  line-height: 1.5rem;
  letter-spacing: 0.04rem;
  font-size: 12px;
  color: #6e6e6e;
  margin-top: 0.6rem;
  margin-bottom: 2rem;
}

.inputContainer {
  position: relative;
  margin-top: 1.6rem;
}

.subtitleWrapper {
  display: flex;
  position: relative;
}

.save {
  position: fixed;
  width: calc(100% - 12rem);
  right: 0;
  bottom: 0;
}

html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
strong {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}
em,
i {
  font-style: italic;
}
a {
  margin: 0;
  padding: 0;
  vertical-align: baseline;
  font-size: 100%;
  text-decoration: none;
  background: transparent;
}
:focus {
  outline: none;
}
textarea,
select {
  vertical-align: middle;
  appearance: none;
  outline: 0;
}
* {
  box-sizing: border-box;
}

input,
textarea {
  -webkit-appearance: none;
  border-radius: 0;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

button {
  background-color: transparent;
  font-family: "Source Sans Pro", sans-serif;
  padding: 0;
  border: 0;
}

html {
  font-size: 10px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -moz-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 400;
}

.box-wide,
.box-extra-wide,
.box-narrow {
  width: 92%;
  margin: auto;
  min-width: 30rem;
}
.box-wide {
  position: relative;
  max-width: 122rem;
}
.box-extra-wide {
  max-width: 100%;
}

.box-narrow {
  max-width: 87rem;
}

h1 {
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 3.8rem;
  font-weight: bold;
  letter-spacing: 1.27px;
  line-height: 4.7rem;
}

h2 {
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 2.4rem;
  font-weight: bold;
  letter-spacing: 0.8px;
  line-height: 3.1rem;
}

h3 {
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
}

p {
  color: #333;
  font-family: "Source Sans Pro", sans-serif;
  font-size: 1.3rem;
  letter-spacing: 0.43px;
  line-height: 1.7rem;
}

ul {
  list-style: none;
}

li {
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-right: 1.5rem;
}

.responsive-image {
  width: 100%;
  height: auto;
}

@media all and (-ms-high-contrast: none) {
  .box {
    margin-bottom: 2rem;
  }
  .box-full {
    width: 100%;
    float: left;
    box-sizing: border-box;
  }
  .box-half {
    width: 50%;
    float: left;
    box-sizing: border-box;
  }
  .box-third {
    width: 33.333%;
    float: left;
    box-sizing: border-box;
  }
  .box-two-thirds {
    width: 66.66%;
    float: left;
    box-sizing: border-box;
  }
  .box-quarter {
    width: 25%;
    float: left;
    box-sizing: border-box;
  }
  .box-three-quarters {
    width: 75%;
    float: left;
    box-sizing: border-box;
  }

  .column-grid {
    width: calc(100% + 3rem);
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
  .column-grid > .box-quarter {
    width: calc(25% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .column-grid > .box-third {
    width: calc(33.333% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .column-grid > .box-two-thirds {
    width: calc(66.66% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .column-grid > .box-full {
    width: calc(100% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .column-grid > .box-half {
    width: calc(50% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
    position: relative;
  }
  .column-grid > .box-three-quarters {
    width: calc(75% - 3rem);
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  @media all and (max-width: 768px) {
    .box-third,
    .box-quarter {
      width: 50%;
    }
    .box-two-thirds,
    .box-three-quarters {
      width: 100%;
    }

    .column-grid > .box-third,
    .column-grid > .box-quarter {
      width: calc(50% - 3rem);
    }
    .column-grid > .box-two-thirds,
    .column-grid > .box-three-quarters {
      width: calc(100% - 3rem);
    }
  }

  @media all and (max-width: 540px) {
    .box-third,
    .box-quarter,
    .box-half {
      width: 100%;
    }

    .column-grid > .box-third,
    .column-grid > .box-quarter,
    .column-grid > .box-half {
      width: calc(100% - 3rem);
    }
  }
}

@supports (display: grid) {
  .column-grid {
    display: grid;
    grid-template-columns: repeat(12, minmax(0, 1fr));
    grid-gap: 1rem;
  }
  .box-quarter {
    grid-column: span 3;
  }
  .box-third {
    grid-column: span 4;
  }
  .box-half {
    grid-column: span 6;
  }
  .box-two-thirds {
    grid-column: span 8;
  }
  .box-full {
    grid-column: 1 / -1;
  }

  @media all and (max-width: 1024px) {
    .box-third {
      grid-column: span 6;
    }

    .box-two-thirds {
      grid-column: 1/-1;
    }

    .box-quarter {
      grid-column: span 4;
    }
  }

  @media all and (max-width: 768px) {
    .box-third,
    .box-two-thirds,
    .box-half {
      grid-column: 1 / -1;
    }

    .box-quarter {
      grid-column: 1 / 6;
    }
  }

  @media all and (max-width: 540px) {
    .box-quarter,
    .box-third,
    .box-half,
    .box-two-thirds {
      grid-column: 1 / -1;
    }
  }
}

/*categories react-select*/

.products-categories {
  position: relative;
  margin-right: 4rem;
  border: 1px solid #e3e1e1 !important;
}

.products-categories-select__value-container {
  padding-left: 1.2rem;
  padding-top: 1.7rem;
  padding-bottom: 0.8rem;
  font-size: 1.5rem;
  color: #333;
  width: 32rem;
}

.products-categories-select__control {
  border: none !important;
  border-radius: 0 !important;
}

.products-categories-select__control--is-focused {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.products-categories-select__menu {
  border-radius: 0 !important;
}

.products-categories-select__multi-value {
  border-radius: 12px !important;
  color: #fff !important;
  background: #333333 !important;
}

.products-categories-select__multi-value__label {
  color: #fff !important;
  font-size: 11px !important;
}

.products-categories-select__multi-value__remove {
  border-radius: 12px !important;
  color: #e2e2e2 !important;
  background: #333333 !important;
}
.products-categories-select__multi-value__remove:hover {
  cursor: pointer;
}

.products-categories-select__dropdown-indicator {
  color: #ffb707 !important;
}

.products-categories-select__option {
  font-size: 14px !important;
}
.products-categories-select__option--is-focused {
  background-color: #e3e1e1 !important;
}

.assets-products-categories {
  position: relative;
  margin-right: 4rem;
  border: 1px solid #e3e1e1 !important;
  margin-top: 1rem;
  width: 100%;
}

.assets-products-categories-select__value-container {
  padding-left: 1.2rem;
  padding-top: 1.7rem;
  padding-bottom: 0.8rem;
  font-size: 1.1rem;
  color: #333;
  width: 32rem;
}

.assets-products-categories-select__control {
  border: none !important;
  border-radius: 0 !important;
  background-color: #f6f6f6 !important;
}

.assets-products-categories-select__control--is-focused {
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
}

.assets-products-categories-select__menu {
  border-radius: 0 !important;
}

.assets-products-categories-select__multi-value {
  border-radius: 12px !important;
  color: #fff !important;
  background: #333333 !important;
}

.assets-products-categories-select__multi-value__label {
  color: #fff !important;
  font-size: 11px !important;
}

.assets-products-categories-select__multi-value__remove {
  border-radius: 12px !important;
  color: #e2e2e2 !important;
  background: #333333 !important;
}
.assets-products-categories-select__multi-value__remove:hover {
  cursor: pointer;
}

.assets-products-categories-select__dropdown-indicator {
  color: #ffb707 !important;
}

.assets-products-categories-select__option {
  font-size: 14px !important;
}
.assets-products-categories-select__option--is-focused {
  background-color: #e3e1e1 !important;
}

.container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 2.4rem 3.2rem 1.55rem 4rem;
  border-bottom: 1px solid #f2f2f2;
}

.title {
  color: #9c9797;
}

.name {
  color: #333;
  font-size: 20px;
  font-weight: bold;
  letter-spacing: 0.67px;
  line-height: 32px;
}

.actions {
  display: flex;
  align-items: flex-end;
}

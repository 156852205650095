.container {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 1.2rem;
  width: 12rem;
  cursor: pointer;
}

.active.container {
  background-color: #000;
}

.iconContainer {
  margin-bottom: 0.8rem;
  position: relative;
}

.iconInactive {
  visibility: visible;
}

.iconActive {
  position: absolute;
  visibility: hidden;
}

.active .iconActive {
  visibility: visible;
}

.active .iconInactive {
  visibility: hidden;
}

.text {
  opacity: 0.8;
  color: #ffffff;
  font-size: 12px;
  font-weight: bold;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  margin: 0 auto;
}

.title {
  opacity: 0.8;
  margin-bottom: 1.2rem;
}

.description {
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 18px;
}

.item {
  display: flex;
  align-items: center;
}

.star {
  width: 0.9rem;
  margin-right: 0.3rem;
}

.number {
  font-size: 1.6rem;
  font-weight: 700;
  margin-left: 0.9rem;
  color: #333333;
}

.noScore {
  display: flex;
  align-items: center;
}

.noScoreStar {
  width: 1.6rem;
  margin-right: 0.62rem;
}

.noScoreText {
  color: #9c9797;
}

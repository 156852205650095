.container {
  display: flex;
  padding: 0.4rem;
  align-items: center;
  align-content: center;
  border: 1px solid #e2e2e2;
  border-radius: 12px;
  background-color: #ffffff;
  width: 9.6rem;
}

.stateContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.state {
  color: #9c9797;
  font-size: 12px;
  letter-spacing: 0.4px;
  line-height: 12px;
  text-align: center;
}

.icon {
  background-position: center;
  background-repeat: no-repeat;
  min-width: 1.6rem;
  height: 1.6rem;
}

.draft {
  background-image: url(../../../static/svg/draft.svg);
}

.validated {
  background-image: url(../../../static/svg/validated.svg);
}

.submitted {
  background-image: url(../../../static/svg/pending.svg);
}

.personal_use {
  background-image: url(../../../static/svg/draft.svg);
}

.container {
  position: relative;
}

.actionsContainer {
  position: absolute;
  top: -3.2rem;
  right: 1.4rem;
}

.x {
  display: flex;
}

.selectedSetsCount {
  opacity: 0.55;
  margin-right: 1.6rem;
}

.deleteSetBtn {
  cursor: pointer;
  padding: 0;
  background: transparent;
  border: none;
  color: #ffa500;
  font-family: "Source Sans Pro";
  font-size: 13px;
  font-weight: bold;
  letter-spacing: 0.43px;
  line-height: 17px;
}

.formContainer {
  padding: 3.3rem 4rem;
  border-radius: 0.8rem;
  background-color: #fff;
}

.thumbnailsWrapper {
  height: 100vh;
  overflow-y: hidden;
  overflow: auto;
  padding-bottom: 20rem;
}

.thumbnailsWrapper::-webkit-scrollbar {
  display: none;
}

.thumbnailsContainer {
  display: flex;
  flex-wrap: wrap;
}

.addImageSetThumbnail {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 18.4rem;
  height: 18.4rem;
  border: 1px dashed #ffa500;
  margin-right: 1.4rem;
  cursor: pointer;
}

.addImageSetThumbnail h3 {
  color: #ffa500;
}

.box {
  display: flex;
  flex-flow: column;
  align-items: center;
  position: relative;
  width: 18.4rem;
  margin-right: 1.4rem;
  margin-bottom: 2.5rem;
}

.thumbnail {
  position: relative;
  width: 100%;
  height: 18.4rem;
  margin-bottom: 1.6rem;
}

.thumbnail:hover > .viewSetBtn {
  visibility: visible;
}

.viewSetBtn {
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  top: calc(50% - 3.2rem / 2);
  left: calc(50% - (9.6rem / 2));
  border-radius: 16px;
  border: none;
  padding: 0.85rem 2.4rem;
  font-family: "Source Sans Pro";
  background-color: #ffb707;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
}

.thumbnail:hover > .selectImageSetContainer {
  visibility: visible;
}

.selectImageSetContainer {
  box-sizing: border-box;
  visibility: hidden;
  position: absolute;
  cursor: pointer;
  top: 0.75rem;
  left: 0.75rem;
  height: 24px;
  width: 24px;
  border: 1px solid #a5a5a5;
  border-radius: 50%;
}

.checked.selectImageSetContainer {
  border: none;
}

.selectedImageSetIcon {
  visibility: hidden;
}

.checked.selectedImageSetIcon {
  visibility: visible;
}

.imageSetStateContainer {
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
}

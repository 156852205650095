.background {
  height: 100vh;
  width: 100vw;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.1);
}

.container {
  position: absolute;
  width: 42rem;
  background-color: #fff;
  padding: 4rem;
  top: -4rem;
  left: 38rem;
  z-index: 20;
}

.innerContainer {
  position: relative;
}

.closeIcon {
  cursor: pointer;
  position: absolute;
  right: -1.4rem;
  top: -1.4rem;
}

.title {
  font-size: 18px;
  margin-bottom: 0.8rem;
}

.subtitle {
  color: #333;
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 18px;
  margin-bottom: 1.6rem;
}

.gifContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 2.4rem;
}

.cancelBtn {
  height: 33px;
  width: 114px;
  border: 1px solid #ffa500;
  border-radius: 16px;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  padding: 0;
  cursor: pointer;
}

.container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.header {
  width: 100%;
  padding: 2rem 5.6rem;
  background-color: #fff;
}

.titleContainer {
  margin-top: 2rem;
  margin-bottom: 2.4rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.title {
  font-weight: 500;
}

.closeIcon {
  cursor: pointer;
}

.footerBar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: fixed;
  padding-right: 4rem;
  height: 8rem;
  width: 100%;
  bottom: 0;
  background-color: #fff;
}

.btns {
  display: flex;
  align-items: center;
}

.btns button:first-child {
  margin-right: 2rem;
}

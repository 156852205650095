.container {
  position: relative;
  width: 100%;
  height: 100%;
}

.image {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.checkedCircle {
  position: absolute;
  cursor: pointer;
  top: 0.8rem;
  left: 0.8rem;
  background-color: #ffa500;
  border-radius: 50%;
}

.checkedCircleHidden {
  visibility: hidden;
}

.uncheckedCircle {
  box-sizing: border-box;
  position: absolute;
  cursor: pointer;
  cursor: pointer;
  top: 0.8rem;
  left: 0.8rem;
  height: 24px;
  width: 24px;
  border: 1px solid #a5a5a5;
  border-radius: 50%;
}

.container:hover > .uncheckedCircle {
  visibility: visible;
}

.coverImageBtn {
  position: absolute;
  display: flex;
  align-items: center;
  height: 2.4rem;
  bottom: 0.8rem;
  left: 0.8rem;
  border-radius: 12px;
  padding: 0.4rem 0.9rem 0.4rem 0.5rem;
  background-color: rgba(226, 226, 226, 0.8);
}

.container:hover > .coverImageBtn {
  visibility: visible;
}

.checkedCoverImageIcon {
  margin-right: 0.6rem;
}

.uncheckedCoverImageIcon {
  box-sizing: border-box;
  cursor: pointer;
  height: 1.7rem;
  width: 1.7rem;
  border-radius: 50%;
  margin-right: 0.6rem;
  border: 1px solid #aeaeae;
  background-color: rgba(243, 243, 243, 0.8);
}

.coverImageBtn p {
  color: #333;
  font-weight: bold;
}

.makeMainPhoto {
  opacity: 0;
}

.container:hover .makeMainPhoto {
  opacity: 1;
}

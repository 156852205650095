.tabBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background: transparent;
  border: none;
  padding-bottom: 1.5rem;
  margin-right: 2.4rem;
}

.isActive {
  border-bottom: 3px solid #ffa500;
  color: #333;
  font-size: 1.5rem;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  padding-left: 0;
}

.notActive {
  color: #9c9797;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
}

.count {
  margin-left: 0.8rem;
  border-radius: 8.5px;
  padding: 0.2rem 0.6rem;
  background-color: #ededed;
  font-weight: 500;
  font-size: 11px;
  letter-spacing: 0.37px;
  line-height: 14px;
}

.container {
  margin-top: 16rem;
  display: flex;
  flex-direction: column;
}

.title {
  text-align: center;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.8rem;
  letter-spacing: 0.6px;
  line-height: 2.3rem;
  text-align: center;
  margin-bottom: 3rem;
}

.inputWrapper {
  position: relative;
  width: 32rem;
  margin: 0 auto;
  margin-bottom: 3rem;
}

.requestContainer {
  display: flex;
  flex-flow: column;
  align-items: center;
}

@media all and (max-width: 540px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10rem);
    margin-top: 0;
  }

  .form {
    width: 100%;
  }

  .inputContainer {
    width: 100%;
  }
}

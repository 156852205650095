.error {
  color: #bb271d;
  font-size: 1rem;
  position: absolute;
  bottom: -2rem;
  left: 1.2rem;
}

.checkboxError {
  color: #bb271d;
  font-size: 1rem;
  position: absolute;
  bottom: 0;
  left: 0;
}

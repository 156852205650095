.multiSelectInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 6.2rem;
  border-top: 0.1rem solid #e3e1e1;
}

.count {
  font-size: 1.4rem;
  margin-right: 2.5rem;
}

.delete {
  margin-right: 1.6rem;
}

.cancel {
}

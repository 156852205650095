.container {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 4.8rem;
}

.uploadTab {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 7rem;
  margin-right: 1px;
}

.uploadTab:first-child {
  padding-right: 4rem;
  border-right: 1px solid #f2f2f2;
}

.uploadTab:nth-child(2) {
  padding-left: 4rem;
}

.textContainer {
  display: flex;
  flex-flow: column;
}

.text {
  margin-bottom: 0.4rem;
}

.uploadBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 32px;
  padding: 0 1.6rem;
  border-radius: 20px;
  background-color: #ffb707;
  border: none;
}

input[type="file"] {
  display: none;
}

.label {
  color: #000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  margin-left: 0.4rem;
}

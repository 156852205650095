.container {
  padding: 4.8rem 5.6rem;
  background: #fff;
  border: 1px solid #e3e1e1;
  width: 100%;
}

.subtitle {
  font-size: 1.5rem;
  font-weight: bold;
  line-height: 1.9rem;
  letter-spacing: 0.05rem;
}

.inputs {
  margin-top: 2.4rem;
}

.inputs input {
  border: 1px solid #e3e1e1;
  width: 32rem;
  font-size: 1.5rem;
  color: #333;
  padding-left: 1.2rem;
  padding-top: 1.7rem;
  padding-bottom: 0.8rem;
}

.inputContainer {
  display: flex;
  flex-flow: column;
  position: relative;
  margin-top: 1rem;
}

.placeholder {
  position: absolute;
  pointer-events: none;
  left: 1.3rem;
  top: 0.6rem;
  transition: 0.2s ease all;
  color: #969696;
  font-size: 0.9rem;
}

.changePasswordBtn {
  margin-top: 2.4rem;
  cursor: pointer;
  max-width: 14.8rem;
  background-color: transparent;
  border: 1px solid #ffb707;
  padding: 0.85rem 1.55rem;
  color: #000000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
}

.changePasswordform {
  display: flex;
  justify-content: center;
  height: 48rem;
  background-color: #fff;
  width: 100%;
  padding-top: 5rem;
  border: 1px solid #e3e1e1;
}

.save {
  position: fixed;
  width: calc(100% - 12rem);
  right: 0;
  bottom: 0;
}

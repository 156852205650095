.container {
  margin-top: 16rem;
}

.iconContainer {
  margin-left: 16.6rem;
  transform: translateY(2.9rem);
}

.icon {
  height: 198px;
  width: 287px;
}

.title {
  color: #333;
  font-weight: 700;
  letter-spacing: 1.27px;
  line-height: 4.7rem;
  margin-bottom: 1rem;
}

.subtitle {
  font-size: 1.8rem;
  letter-spacing: 0.6px;
  line-height: 2.3rem;
  margin-bottom: 3rem;
}

.highlight {
  font-weight: 700;
}

.label {
  margin-bottom: 1rem;
}

.form {
  width: 100%;
}

.inputsContainer {
  display: flex;
  position: relative;
  align-items: center;
}

.inputWrapper {
  width: 32rem;
}

.space {
  margin-right: 2rem;
}

@media all and (max-width: 1024px) {
  .iconContainer {
    display: flex;
    justify-content: center;
    margin-left: 0;
  }
}

@media all and (max-width: 768px) {
  .iconContainer {
    display: none;
  }
}

@media all and (max-width: 540px) {
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 10rem);
    margin-top: 0;
  }

  .inputsContainer {
    flex-direction: column;
  }

  .inputWrapper {
    width: 100%;
    margin-bottom: 2rem;
  }

  .space {
    margin-right: 0;
  }
}

.container {
  position: relative;
  padding: 5.2rem 5.6rem;
  background-color: #f2f2f2;
  height: calc(100% - 12rem);
  overflow: hidden;
}

.imageSetPreviews {
  overflow: initial;
}

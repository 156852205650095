.container {
  position: relative;
  padding: 2.3rem;
  padding-top: 0;
  overflow-y: hidden;
  overflow: auto;
  height: 100vh;
  padding-bottom: 40rem;
}

.container::-webkit-scrollbar {
  display: none;
}

.faded {
  opacity: 0.3;
}

.addNewProductBtnContainer {
  margin-bottom: 0.8rem;
}

.deleteBtnContainer {
  padding: 1.1rem 0;
  border-bottom: 1px solid #e3e1e1;
}

.addImagesToSetTitle {
  opacity: 0.8;
  padding: 1.55rem 0;
}

.addImagesToSetBtnContainer {
  padding: 1.1rem 0;
}

.imageDescriptionContainer {
  margin-top: 1.6rem;
  padding-bottom: 1.6rem;
  border-bottom: 1px solid #e3e1e1;
}

.productsContainer {
  transform: translateX(-0.4rem);
}

.closeIcon {
  position: absolute;
  top: 1.6rem;
  right: 1.6rem;
  cursor: pointer;
}

.title {
  font-size: 18px;
  padding-top: 2.45rem;
  margin-bottom: 0.8rem;
}

.subtitle {
  font-size: 14px;
  letter-spacing: 0.47px;
  line-height: 18px;
}

.nrOfImages {
  opacity: 0.8;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  margin-top: 2.45rem;
  margin-bottom: 1.6rem;
}

.imagesContainer {
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #e3e1e1;
}

.imagesInnerContainer {
  display: flex;
  flex-wrap: wrap;
  max-height: 27.6rem;
  overflow: hidden;
  margin-bottom: 0.6rem;
}

.showAllImages.imagesInnerContainer {
  max-height: inherit;
}

.seeAllBtnContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1.6rem;
  width: 100%;
}

.seeAllBtn {
  height: 17px;
  width: 64px;
  border: 1px solid #e3e1e1;
  border-radius: 12px;
  color: #333;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.4px;
  line-height: 15px;
  text-align: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

.imageLarge {
  margin-top: 4.85rem;
  width: 26.8rem;
  height: 26.8rem;
  margin-bottom: 1.6rem;
}

.imageSmall {
  width: 12.8rem;
  height: 12.8rem;
  margin-bottom: 1rem;
}

.thumbnail {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
}

.imageSmall:nth-of-type(odd) {
  margin-right: 1rem;
}

.newProductFormContainer {
  position: relative;
  padding-left: 1.95rem;
  padding-bottom: 1.6rem;
}

.x {
  background-color: white;
  position: fixed;
  bottom: 0;
  padding-left: 3.8rem;
  height: 6.1rem;
  width: 31.2rem;
  transform: translateX(-2.2rem);
}

.y {
  display: block;
  height: 1px;
  width: 31.2rem;
  background-color: #e3e1e1;
  transform: translateX(-4.15rem);
  margin-bottom: 1.5rem;
}

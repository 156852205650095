.container {
  position: relative;
  width: 100%;
  height: 100vh;
}

.imagesContainer {
  background-color: #f2f2f2;
  position: relative;
  height: 100vh;
  width: 100%;
  padding: 0 5.2rem;
  overflow-y: hidden;
  overflow: auto;
  padding-bottom: 30rem;
}

.imagesContainer::-webkit-scrollbar {
  display: none;
}

.imagesGroup {
  padding-top: 2.4rem;
}

.date {
  color: #333;
  font-size: 1.6rem;
  font-weight: bold;
  letter-spacing: 0.53px;
  line-height: 2rem;
  padding-bottom: 1.6rem;
  margin-left: 0.4rem;
}

.images {
  display: flex;
  flex-wrap: wrap;
}

.image {
  position: relative;
  width: 12rem;
  height: 12rem;
  margin: 0 0.4rem;
  margin-bottom: 0.8rem;
}

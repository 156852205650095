.container {
  margin: 5rem 0;
}

.title {
  margin-bottom: 0.2rem;
}

.subtitle {
  font-size: 18px;
  letter-spacing: 0.6px;
  line-height: 23px;
  margin-bottom: 2rem;
}

.tabs {
  display: flex;
  justify-content: space-between;
  margin-bottom: 4rem;
}

.activeTab {
  width: calc(50% - 2px);
  background-color: #ffb707;
}

.activeBtn {
  background: transparent;
  border: none;
  color: #333333;
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.5px;
  line-height: 19px;
  padding: 0.8rem 2rem;
}

.notActiveTab {
  width: calc(50% - 2px);
  background-color: #e2e2e2;
}

.notActiveBtn {
  background: transparent;
  border: none;
  color: #5b5b5b;
  font-size: 15px;
  letter-spacing: 0.5px;
  line-height: 19px;
  padding: 0.8rem 2rem;
}

.navBtnsContainer {
  display: flex;
  justify-content: flex-end;
  margin-top: 2.4rem;
}

.nextBtn {
  cursor: pointer;
  background-color: #ffb707;
  color: #000;
  border: none;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  width: 9.8rem;
  height: 4rem;
}

.prevBtn {
  cursor: pointer;
  background-color: #fff;
  color: #000;
  border: 1px solid #ffb707;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  font-weight: 700;
  width: 9.8rem;
  height: 4.2rem;
  margin-right: 1rem;
}

.submitBtn {
  cursor: pointer;
  background-color: #ffb707;
  text-align: center;
  color: #000;
  border: none;
  font-weight: 700;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  padding: 0 3rem;
  height: 4.2rem;
}

.loadingBtn {
  background-color: #ffdc83;
  font-weight: 700;
  text-align: center;
  color: #000;
  border: none;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  padding: 0 3rem;
  height: 4.2rem;
}

@media all and (max-width: 768px) {
  .tabs {
    flex-flow: column;
  }

  .activeTab {
    margin-bottom: 0.6rem;
  }

  .activeTab {
    width: 100%;
  }

  .notActiveTab {
    width: 100%;
  }
}

@media all and (max-width: 540px) {
  .nextBtn {
    width: 100%;
  }

  .navBtnsContainer {
    padding-right: 0;
  }

  .btnsWrapper {
    width: 100%;
    display: flex;
  }

  .prevBtn {
    width: 50%;
  }

  .submitBtn {
    width: 50%;
  }
  .loadingBtn {
    width: 50%;
  }
}

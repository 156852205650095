.headRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  align-items: center;
  width: 100%;
  padding: 0 3.2rem;
  margin-bottom: 1.5rem;
  font-size: 1.3rem;
  line-height: 1.6rem;
  color: #9c9797;
}

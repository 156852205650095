.input {
  background: transparent;
  border: none;
  border: 1px solid #969696;
  padding: 1.2rem;
  font-size: 1.5rem;
  line-height: 1.9rem;
  letter-spacing: 0.5px;
  width: 100%;
  height: 4.2rem;
}

.greyInput {
  background-color: #f6f6f6;
  height: 3.3rem;
  border: 1px solid #e3e1e1;
  color: #9c9797;
  font-size: 11px;
  letter-spacing: 0.37px;
  line-height: 14px;
}

.submitBtn {
  cursor: pointer;
  width: initial;
  background-color: #ffb707;
  font-weight: bold;
  text-align: center;
  color: #000;
  border: none;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  padding: 0 3rem;
  height: 4.2rem;
}

.roundSubmitBtn {
  cursor: pointer;
  width: initial;
  background-color: #ffb707;
  border-radius: 20px;
  font-weight: bold;
  text-align: center;
  color: #000;
  border: none;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  padding: 0 2.8rem;
  height: 4.2rem;
}

.loadingBtn {
  cursor: initial;
  background-color: #ffdc83;
  font-weight: bold;
  text-align: center;
  color: #000;
  border: none;
  font-size: 1.5rem;
  letter-spacing: 0.5px;
  line-height: 1.9rem;
  padding: 0 3rem;
  height: 4.2rem;
}

@media all and (max-width: 540px) {
  .submitBtn {
    width: 100%;
  }

  .loadingBtn {
    width: 100%;
  }
}

.container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 0.5rem;
  z-index: 99999;
}

.background {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  top: 0;
  background: rgba(255, 255, 255, 0.2);
}

.bar {
  background-image: linear-gradient(
    to right,
    #ffb707 0.3%,
    #ff5111 88%,
    #ffb707 102%
  );
  background-size: 200% auto;
  height: 5px;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  right: 0;
  -webkit-animation: preloaderAnimation 2s linear infinite forwards;
  animation: preloaderAnimation 2s linear infinite forwards;
}

@keyframes preloaderAnimation {
  0% {
    background-position: 0 0;
  }

  50% {
    background-position: 100% 0;
  }

  100% {
    background-position: 200% 0;
  }
}
